<template>
  <div class='footer'>
    <div class="en">Copyright © 2024-现在 <span @click="toIcp">豫ICP备2024054427号</span></div>
    <div class="zh_cn">高仕登(河南)教育科技有限公司 版权所有</div>
  </div>
</template>

<script>

export default {
  data () {
    return {

    }
  },
  created () {
  },
  methods: {
    toIcp () {
      window.open('https://beian.miit.gov.cn/')
    }
  },
}
</script>

<style scoped lang='scss'>
.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
  font-size: 12px;
  color: #909399;
  margin-top: 50px;
  text-align: center;

  span {
    cursor: pointer;

    &:hover {
      opacity: 0.6;
    }
  }
}
</style>
